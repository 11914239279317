<template>
  <div class='container'>
    <div class="head-bg"></div>
    <div class="steps">
      <div :class="['step',step===1?'achieve':'']">
        <div class="circle" v-if="step!==1">
          1
        </div>
        <img src="@/assets/project/active.png" alt="" v-else>
        <p>选择量表</p>
      </div>
      <div :class="['step',step===2?'achieve':'']">
        <div class="circle" v-if="step!==2">
          2
        </div>
        <img src="@/assets/project/active.png" alt="" v-else>
        <p>填写信息</p>
      </div>
      <div :class="['step',step===3?'achieve':'']">
        <div class="circle" v-if="step!==3">
          3
        </div>
        <img src="@/assets/project/active.png" alt="" v-else>
        <p>发布成功</p>
      </div>
    </div>
    <div class="content" v-if="step===1">
      <div class="tabs">
        <div :class="['tab',catId===item.id?'active':'']" v-for="(item,index) in tabs" :key="item.id" @click="tabChange(item.id,item.title)" >
          {{ item.title }}
        </div>
      </div>
      <div class="box" v-if="recommends.length>0">
        <div class="box-title">
          <p>平台推荐</p>
        </div>
        <div class="recommend">
          <div :class="['recommend-content',recommend===index?'active':'']" v-for="(item,index) in recommends" :key="index" @click="chooseRecommend
chooseRecommend(index)">
            <div style="display: flex;align-items: center;">
              <van-image
                width="14.9333vw"
                height="14.9333vw"
                fit="cover"
                :src="item.imgProjectLogo"
              />
              <div style="margin-left: 4vw;flex: 1;display: flex;flex-direction: column;justify-content: space-around;height:14.9333vw">
                <p class="omit" style="font-size: 3.7333vw;font-weight: 550;">{{item.name}}</p>
                <p class="omit" style="font-size: 3.2vw;color:#868686 ;">{{item.intro}}</p>
              </div>
            </div>
            <p style="margin-top: 2.6667vw;font-size: 2.9333vw;font-weight: 550;">约30分钟 {{item.questionNum}}题</p>
          </div>
        </div>
      </div>
      <div class="box">
        <div class="box-title">
          <p>{{catName==='自选'?'自定义组合（可多选)':catName+'量表'}}</p>
        </div>
        <div class="project">
          <div :class="['project-content', projectIndexArr.includes(index) ?'active':'']" v-for="(item,index) in projects" :key="index" @click="chooseProject(index)">
            <div style="display: flex;align-items: center;">
              <van-image
                width="14.9333vw"
                height="14.9333vw"
                fit="cover"
                :src="item.imgProjectLogo"
              />
              <div style="margin-left: 4vw;flex: 1;display: flex;flex-direction: column;justify-content: space-around;height:14.9333vw">
                <p class="omit" style="font-size: 3.7333vw;font-weight: 550;">{{item.name}}</p>
                <p class="omit" style="font-size: 3.2vw;color:#868686 ;">{{item.intro}}...</p>
              </div>
            </div>
            <p style="margin-top: 2.6667vw;font-size: 2.9333vw;font-weight: 550;">约20分钟 {{item.questionNum}}题</p>
          </div>
        </div>
      </div>
    </div>
    <div class="content" v-if="step===2">
      <div class="form">
        <div class="option">
          <div class="title">* 测评标题</div>
          <input type="text" v-model="form.title" placeholder="请输入测评标题">
        </div>
        <div class="option" >
          <div class="title">* 开始时间</div>
          <input type="text" v-model="form.startTime" placeholder="请选择开始时间" @click="pupupShow('starTime')" readonly>
        </div>
        <div class="option">
          <div class="title">* 结束时间</div>
          <input type="text" v-model="form.endTime" placeholder="请选择结束时间" @click="pupupShow('endTime')" readonly>
        </div>
        <div class="switch">
          <p style="font-size: 3.7333vw;">开放报告</p>
          <van-switch v-model="form.showToUser" size="6.4vw" active-color="#5EDBC9" :active-value="1" :inactive-value="0"/>
        </div>
        <div class="switch">
          <p style="font-size: 3.7333vw;">定向测评</p>
          <van-switch v-model="form.needProfile" size="6.4vw" active-color="#5EDBC9" :active-value="1" :inactive-value="0"/>
        </div>
        <div class="selectPro" v-if="form.needProfile" >
          <p @click="$router.push({path:'select',query:{form:JSON.stringify(form)}})">
            {{ this.$route.query.selectNames?'重新选择':'选择测评年级/班级' }}
          </p>
        </div>
        <div class="option" v-else>
          <div class="title">* 测评人数</div>
          <input type="text" v-model="form.totalNum" placeholder="请输入测评人数">
        </div>
        <div style="margin:0 2.6667vw;display: flex;align-items: center;margin-top: 4vw;font-weight: 550;font-size: 14;flex-wrap: wrap;" v-if="$route.query.selectNames&&form.needProfile">
            <p style="margin-right: 2.6667vw;">已选择: </p>
            <span v-for="(item,index) in JSON.parse($route.query.selectNames)" :key="item" style="flex-shrink: 0;line-height: 6.9333vw;">{{ item }}{{ index!==JSON.parse($route.query.selectNames).length-1?'、':''}}</span>
          </div>
        <div class="projects">
          <p style="font-size: 3.7333vw;margin-left: 2.6667vw;margin-bottom: 2.6667vw;">测评量表</p>
          <div class="projects-content" v-for="item in selectProArr" :key="item.id">
            <p>{{item.name||item}}</p>
          </div>
        </div>

      </div>
    </div>
    <div class="bottom">
      <button v-if="step===1" @click="next()">下一步</button>
      <button v-if="step===2" @click="publish">发布测评</button>
    </div>
    <van-popup v-model="isPopup" position="bottom" :style="{ height: '40%' }" >
      <van-datetime-picker
        v-model="form.startTime"
        type="datetime"
        title="选择开始时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="starTimeConfim"
        @cancel="isPopup=false"
        v-if="pupupName==='starTime'"
      />
      <van-datetime-picker
        v-model="form.endTime"
        type="datetime"
        title="选择结束时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="endTimeConfim"
        @cancel="isPopup=false"
        v-if="pupupName==='endTime'"
      />
    </van-popup>
  </div>
</template>

<script>
import {bottomLoading} from '@/utils/util.js'
import {projectsV2,wxworkExamAdd,} from '@/api/project.js'
import {examDetail} from '@/api/exam.js'
import moment from "moment"
import { Popup } from 'vant'
export default {
  data(){
    return{
      step:1,
      exam:{},//场次详情
      testerTypeId:1,
      catName:'心理健康',
      catId:1, // 有分类(1:心理健康 2:成长教育 3:学业规划 4:性格职业 100:自由组合量表)
      projectIndex: 0,// 0:平台推荐 1：自由组合
      projects:[],//所有量表
      recommends:'',//推荐量表(单选)
      recommend:-1,
      projectIndexArr:[],//多选量表索引数组
      selectProArr:[],//已选择的量表数组
      tabIndex:0,
      isPopup:false,
      loading:false,
      pupupName:'',//当前弹窗
      // 时间转化
      starTime:new Date(),
      endTime:new Date(),
      //报告样例
      reportTemplates: {
            1: 76839,
            5: 76840,
            6: 76841,
            22: 76842,
            40: 78089,
            24: 76847,
            27: 78085,
            23: 76990,
            31: 76992,
            36: 76993,
            37: 76994,
            39: 76995,
            52: 78082,
            51: 78071,
            28: 78062
        },
      tabs:[
        {id:1,title:'心理健康'},
        {id:2,title:'成长教育'},
        {id:3,title:'学业规划'},
        {id:100,title:'自选'},
      ],
      params:{
        page:1,
        pageSize:10,
      },
      form:{
        title:'',
        startTime: '',
        endTime: '',
        projectIds: '',
        needProfile: 0,
        profilePath: '',
        totalNum: '',
        showToUser: 1,
        leaderTaskId: '',
        testerTypeId: 1,
        leafNode: [],
        // -------
        // open:false,
        // dx:false,
      },
      minDate:new Date(),
      maxDate:new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()),
    }
  },
  async created(){
    await this.getProjectsV2()
    let {step,form,examId}=this.$route.query
    // console.log(215,step,form);
    if(examId){
      this.step=2
      this.getExamDetail()
    }
    if(step==2&&form){
      this.step=Number(step),
      this.form=JSON.parse(form)
      // console.log(this.projects,this.form.projectIds.split(','));
      this.selectProArr=this.projects.filter(item=>this.form.projectIds.split(',').indexOf(String(item.id))>-1)
    }
  },
  mounted(){
   /*  if(this.step===1){
      bottomLoading(this.$refs.bottom,this.loadNextPage)
    } */
  },
  methods:{
    async getExamDetail(){
      const id=this.$route.query.examId
      const res = await examDetail({id})
      this.exam = res.data.exam
      const { title,startTime,endTime,projectId_man,needProfile,
      totalNum,showToUser,leaderTaskId, profilePath, testerTypeId } = res.data.exam
      this.form.id = id
      this.form.title = title
      this.form.startTime = startTime
      this.form.endTime = endTime
      this.form.projectIds = projectId_man
      this.form.needProfile = needProfile
      this.form.totalNum = totalNum
      this.form.showToUser = showToUser
      this.form.leaderTaskId = leaderTaskId
      this.form.testerTypeId = testerTypeId
      // 获取当前选中的量表
      const projectIdArr = projectId_man.split(',')
      
      // console.log(261,projectIdArr);
      this.projects.forEach((item, index) => {
          if (projectIdArr.indexOf(String(item.id)) > -1) {
              this.selectProArr.push(item.name)
          }
      })
    },
    tabChange(id,title){
      this.catId=id
      this.catName=title
      this.getProjectsV2()
    },
    // 平台推荐量表选择
    chooseRecommend(recommendIndex){
      if (this.recommend === recommendIndex) {
        this.recommend = -1
      } else {
        this.recommend = recommendIndex
      }
      if (this.recommend !== -1) {
        this.projectIndex = 0
        this.projectIndexArr = []
      }
    },
    // 自由组合 - 选择量表
    chooseProject(projectIndex) {
      const projectIndexArr = JSON.parse(JSON.stringify(this.projectIndexArr))
      const index = projectIndexArr.indexOf(projectIndex)
      if (index > -1) {
        projectIndexArr.splice(index, 1)
      } else {
        projectIndexArr.push(projectIndex)
      }
      this.projectIndexArr = projectIndexArr
      if (projectIndexArr.length > 0) {
        this.projectIndex = 1
        this.recommend = -1
      }

    },
    // 获取分类量表
    async getProjectsV2(){
      const res = await projectsV2()
      const list = res.data.list
        let projects = []
        // 学生测评
        if (this.form.testerTypeId === 1) {
          if (this.catId === 100) {
            list.forEach((cat) => {
              if (cat.name !== '学业规划') {
                projects = projects.concat(
                  cat.projects.filter((item) => item.studentUse === 1)
                )
              }
            })
          } else {
            projects = list
              .find((cat) => cat.name === this.catName)
              .projects.filter((item) => item.studentUse === 1)
          }
        } else {
          // 教职工测评
          if (this.catId === 100) {
            list.forEach((cat) => {
              if (cat.name !== '学业规划') {
                projects = projects.concat(
                  cat.projects.filter((item) => item.teacherUse === 1)
                )
              }
            })
          } else {
            projects = list
              .find((cat) => cat.name === this.catName)
              .projects.filter((item) => item.teacherUse === 1)
          }
        }
        // 平台推荐
        const recommends = []
        if (this.catId === 1) {
          // 学生测评
          if (this.form.testerTypeId === 1) {
            const recommend = {
              name: '青少年心理成长综合测评',
              intro:
                '用途：此测评综合了《康奈尔医学指数量表》、《匹茨堡睡眠质量问卷》、《防御方式问卷》，是测评青少年心理全方位指标的有效工具。',
              questionNum: 0,
              projectIds: [1, 5, 6],
              projectNames:
                '康奈尔医学指数量表,匹茨堡睡眠质量问卷,防御方式问卷',
              reportTemplate: 84218
            }
            let questionNum = 0
            projects.forEach((project) => {
              if (recommend.projectIds.includes(project.id)) {
                questionNum = questionNum + project.questionNum
              }
            })
            recommend.questionNum = questionNum
            recommends.push(recommend)
            projects.forEach((project) => {
              if (project.schoolCmd === 1 && project.studentUse === 1) {
                project.projectIds = [project.id]
                project.projectNames = [project.name]
                recommends.push(project)
              }
            })
          } else {
            // 教职工测评
            projects.forEach((project) => {
              if (project.schoolCmd === 1 && project.teacherUse === 1) {
                project.projectIds = [project.id]
                project.projectNames = [project.name]
                recommends.push(project)
              }
            })
          }
        }
        // 报告样例
        projects.forEach((project) => {
          project['reportTemplate'] = this.reportTemplates[project.id] || ''
        })
        this.projects = projects
        this.recommends = recommends
    },
    next(){
      if (this.recommend === -1 && this.projectIndexArr.length === 0) {
        this.$toast.fail('请先选择量表')
        return
      }
      let projectIds = ''
      let projectNames = ''
      if (this.projectIndex === 0) {
        projectIds = this.recommends[this.recommend].projectIds.toString()
        projectNames = this.recommends[this.recommend].projectNames.toString()
      } else {
        projectIds = this.projectIndexArr
          .map((index) => this.projects[index].id)
          .toString()
        projectNames = this.projectIndexArr
          .map((index) => this.projects[index].name)
          .toString()
      }
      this.form.projectIds = projectIds
      this.selectProArr=this.projects.filter(item=>projectIds.indexOf(item.id)>-1)
      this.projectNames = projectNames
      this.step = 2
    },
    pupupShow(name){
      this.pupupName=name
      this.isPopup=true
    },
    starTimeConfim(value){
      this.form.startTime=moment(value).format('YYYY-MM-DD HH:mm:ss')
      this.isPopup=false
    },
    endTimeConfim(value){
      this.form.endTime=moment(value).format('YYYY-MM-DD HH:mm:ss')
      this.isPopup=false
    },
    loadNextPage(){
      this.params.page++
      // console.log('触发',this.params.page);
    },
    async publish(){
      if(this.loading){
        return
      }
      this.loading=true
      if(!this.form.title.trim()){
        this.$toast.fail('请输入标题')
        return
      }
      // console.log(this.form.startTime)
      if(!this.form.startTime){
        this.$toast.fail('请选择开始时间')
        return
      }
      if(!this.form.endTime){
        this.$toast.fail('请选择结束时间')
        return
      }
      if(this.form.needProfile){
        if(this.form.leafNode.length<=0){
          this.$toast.fail('请至少选择一个测评部门')
          return
        }
      }else{
        if(!this.form.totalNum){
          this.$toast.fail('请输入测评测评人总人数')
          return
        }
      }
      // console.log(this.selectProArr.map(item=>item.name).join('、'));
      const res = await wxworkExamAdd(this.form)
      this.loading=false
      this.$router.push({path:'success',query:{id:res.data.examId,projectName:this.selectProArr.map(item=>item.name).join('、')}})
      this.$toast.success('发布成功')
      /* const res2 = await examDetail({id:res.data.examId})
      this.exam=res.data.exam
      this.step=3 */
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  padding-bottom: 18.6667vw;
  min-height: 100%;
  background-color: #fff;
  .head-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 52.2667vw;
    background: linear-gradient(180deg, #C4F3DF 0%, #FFFFFF 100%);
    z-index: 0;
  }
  .steps{
    position: relative;
    padding: 0 8vw;
    height: 22.9333vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // background-color: #fff;
    // box-shadow: 0vw .5333vw 1.6vw 0vw rgba(176, 176, 176, 40);
    z-index: 1;
    .step{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #8DC2BB;
      &::before{
          content: '';
          top: 4vw;
          left: 100%;
          position: absolute;
          width: 20vw;
          height: 1.3333vw;
          background: #BDF1E4;
          border-radius: .8vw .8vw .8vw .8vw;
        }
      &:last-child{
        &::before{
          display: none;
        }
      }
      p{
        margin-top: 2.1333vw;
      }
      img{
        width: 8vw;
        height: 8vw;
        border-radius: 50%;
      }
      .circle{
        position: relative;
        // margin-bottom: 1.8667vw;
        width: 8vw;
        height: 8vw;
        line-height: 8vw;
        font-size: 4.2667vw;
        font-weight: 550;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        background-color: #8dc2bb;
        // border: .2667vw solid rgba(187, 187, 187, 1);
      
      }
    }
    .step.achieve{
      color: #5EDBC9;
    }
  }
  .content{
    position: relative;
    padding: 3.2vw 3.2vw 0 3.2vw;
    .tabs{
      display: flex;
      flex-wrap: nowrap;
      overflow: scroll;
      .tab{
        flex-shrink: 0;
        margin-right: 3.2vw;
        text-align: center;
        padding: 0 2.6667vw ;
        height: 9.8667vw;
        line-height: 9.8667vw;
        font-size: 3.7333vw;
        background-color: #fff;
        &:last-child{
          margin-right: 0;
        }
      }
      .tab.active{
        color: #fff;
        background-color: #545454;
      }
    }
    .box{
      margin-top: 4vw;
      .box-title{
        margin-bottom: 4vw;
        display: flex;
        p{
          padding: .8vw 1.3333vw;
          font-size: 2.9333vw;
          font-weight: 550;
          color: rgba(104, 104, 104, 1);
          border: .2667vw solid rgba(217, 217, 217, 1);
          border-radius: 1.0667vw;
        }
      }
      .recommend,.project{
        .recommend-content,.project-content{
          margin-bottom: 4vw;
          padding: 2.6667vw;
          background-color: #fff;
          border-radius: 2.6667vw;
          box-shadow: 0vw .5333vw 1.6vw 0vw rgba(176, 176, 176, 40);
        }
        .recommend-content.active,.project-content.active{
          border: .8vw solid rgba(94, 94, 94, 1);
        }
      }
    }
    .form{
      padding: 0 4vw;
      box-shadow: 0vw 0vw 2.6667vw 0vw rgba(187,187,187,0.15);
      border-radius: 2.6667vw;
      background-color: #fff;
      .option{
        .title{
          padding: 4vw 0;
          font-size: 3.7333vw;
          // font-weight: 550;
        }
        input{
          padding: 0 4.2667vw;
          width: 100%;
          height: 12.2667vw;
          font-size: 3.4667vw;
          border-radius: 13.3333vw;
          background-color: #F5F7F9;
          &::placeholder{
            color: #B6B7B9;
            // font-weight: 550;
          }
        }
      }
      .switch{
        margin: 5.3333vw 2.6667vw 0 2.6667vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .selectPro{
        display: flex;
        margin: 4vw 0 0 2.6667vw;
        p{
          padding: 0 6.6667vw;
          font-size: 3.7333vw;
          height: 12.2667vw;
          line-height: 12.2667vw;
          color: #fff;
          border-radius: 13.3333vw;
          background-color: #5EDBC9;
        }
      }
      .projects{
        margin: 10.6667vw 0 4vw 0;
        .projects-content{
          margin-top: 2.6667vw;
          padding: 4.2667vw 3.4667vw;
          font-size: 3.4667vw;
          color: #5EDBC9;
          background-color: #ECFDFA;
          border-radius: 13.3333vw;
        }
      }
    }
  }
  .bottom{
    padding: 0 3.4667vw;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 16vw;
    line-height: 16vw;
    button{
      width: 100%;
      height: 14.4vw;
      line-height: 14.4vw;
      text-align: center;
      font-size: 4.8vw;
      color: rgba(255, 255, 255, 1);
      background-color: #5EDBC9;
      border-radius: 13.3333vw;
    }
  }
}
</style>